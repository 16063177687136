import { createRouter, createWebHistory } from 'vue-router';

//Define routes

import DashboardLayout from "@/components/skeleton/BaseLayout.vue";
import NotFound from "@/pages/NotFoundPage.vue";
import DonorsPage from '@/pages/DonorsPage.vue';

// Admin pages
const MatchingPage = () => import("@/pages/MatchingPage.vue");

//Login
const LoginPage = () => import("@/pages/LoginPage.vue");

const routes = [
    {
        path: "/login",
        component: LoginPage,
        beforeEnter: (to, from, next) => {
            // Add any beforeEnter logic if needed
            next();
        }
    },
    {
        path: "/",
        component: DashboardLayout,
        meta: {
            requiresAuth: true,
            roles: ["ROLE_USER"],
        },
        children: [
            {
                path: "/",
                name: "matchings",
                component: MatchingPage,
                icon: "list",
                meta: {
                    requiresAuth: true,
                    roles: ["ROLE_USER"],
                },
            },
            {
                path: "/donors",
                name: "donors",
                component: DonorsPage,
                icon: "list",
                meta: {
                    requiresAuth: true,
                    roles: ["ROLE_USER"],
                },
            },
        ],
    },
    {
        path: "/logout",
        name: "logout",
        component: {
            beforeRouteEnter(to, from, next) {
                localStorage.removeItem("gametiamatch_user");
                next("/login");
            }
        }
    },
    { path: '/:catchAll(.*)', component: NotFound },
];



// create router instance
const router = createRouter({
    history: createWebHistory(),
    routes,
    linkExactActiveClass: "active",
    scrollBehavior: (to) => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    },
});

// navigation guard
router.beforeEach((to, from, next) => {
    //const publicPages = ["/login"];
    //const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem("gametiamatch_user");

    if (to.meta.requiresAuth && !loggedIn) {
        return next("/login");
    }

    if (loggedIn) {
        let user = JSON.parse(localStorage.getItem("gametiamatch_user"));
        if (to.meta.roles) {
            const found = user.roles.some((r) => to.meta.roles.indexOf(r) >= 0);
            if (!found) {
                return next("/login");
            }
        }
    }

    next();
});

export default router;
