<template>
  <section>
    <button-component
      class="btn btn-primary"
      :tooltip="$t('matching.title')"
      @click="this.visible = true"
      :loading="loading"
      ><font-awesome-icon icon="plus" /> Añadir donante</button-component
    >
    <modal-component
      size="modal-xxl"
      v-if="visible"
      v-model:show="visible"
      @close="this.visible = false"
    >
      <template v-slot:modal-title>{{ $t("matching.title") }}</template>
      <template v-slot:modal-body>
        <div class="modal-body purchase-card" style="background-color: #f4f6f9">
          <div class="card">
            <div class="card-body">
              <h3>{{ $t("matching.foto_donante") }}</h3>
              <img
                class="img_to_upload"
                :src="imagenDonante"
                v-if="matching.donante"
              />
              <file-component
                v-model="matching.donante"
                @getB64File="post.b64donante = $event"
                :displayLabel="false"
                label="pedido.informes.archivo"
              />

              <select-component
                :hasValidation="false"
                :displayLabel="true"
                label="matching.genero"
                v-model="post.genero"
                :options="generos"
                :rules="{ required: false }"
              />
              <input-component
                v-model="post.numeroDonante"
                :rules="{ required: false }"
                label="matching.donante"
                placeholder="matching.donante"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modal-buttons>
        <button-component
          class="btn btn-primary"
          :tooltip="$t('matching.realizar_matching')"
          @click="save()"
          :loading="loading"
          >{{ $t("matching.realizar_matching") }}</button-component
        >
      </template>
    </modal-component>
  </section>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      visible: false,
      loading: false,

      generos: [
        { value: "HOMBRE", label: "Masculino" },
        { value: "MUJER", label: "Femenino" },
      ],
      matching: {
        donante: null,
      },
      post: {
        b64donante: null,
        imagenDonante: null,
        numeroDonante: "",
        genero: "HOMBRE",
      },
    };
  },
  created: function () {},
  computed: {
    imagenDonante() {
      return URL.createObjectURL(this.matching.donante);
    },
  },
  methods: {
    async save() {
      this.loading = true;
      try {
        this.post.imagenDonante = this.matching.donante.name;
        await this.$api.trainDonor(this.post);
        this.loading = false;
        this.visible = false;
        //Emit event to reload data
        this.$emit("reload");
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
h3 {
  text-align: center;
}
.img_to_upload {
  max-height: 200px;
  display: block;
  margin: 0 auto;
  padding: 10px;
}
</style>