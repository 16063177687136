// api.js
import axios from 'axios';

const baseURL = process.env.VUE_APP_API_URL;
// Update with your API base URL
const api = axios.create({
	baseURL,
	headers: {
		'Content-Type': 'application/json',
	},
});

// Add a request interceptor
api.interceptors.request.use(
	(config) => {
		const localUser = localStorage.getItem('gametiamatch_user');
		if (localUser) {
			let token = JSON.parse(localUser).token;
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		// Handle request errors here
		return Promise.reject(error);
	}
);

// Add a response interceptor
api.interceptors.response.use(
	(response) => {
		// Do something with successful responses
		return response;
	},
	(error) => {
		// Handle error responses here
		if (error.response && error.response.status === 401) {
			// Handle 401 Unauthorized error
			localStorage.removeItem('gametiamatch_user');
			// You can redirect the user to the login page or handle the error as needed
			window.location.reload();
		}
		return Promise.reject(error);
	}
);

//User management
export const loginUser = async (userData) => {
	try {
		const response = await api.post('/login_check', userData);
		localStorage.setItem('gametiamatch_user', JSON.stringify(response.data));
		return JSON.parse(JSON.stringify(response.data));
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

//Matching
export const getMatchings = async () => {
	try {
		const response = await api.get('/matchings');
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};


export const createMatching = async (matchingData) => {
	try {
		const response = await api.post('/new-matching', matchingData);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
}

export const descargarInforme = async (locale, idMatching, format) => {
	try {
		//Response type blob
		const response = await api.get('/pdf/' + locale + "/" + idMatching + "/" + format, { responseType: 'blob' });
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const getDonors = async () => {
	try {
		const response = await api.get('/donors');
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
}

export const quickMatching = async (matchingData) => {
	try {
		const response = await api.post('/quick-matching', matchingData);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
}

export const trainDonor = async (donorData) => {
	try {
		const response = await api.post('/add-donor', donorData);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
}