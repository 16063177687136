<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Header content -->
    <a href="" class="navbar-brand">
      <img
        :src="logo"
        alt="Gametia Biobank"
        class="brand-image"
        style="width: 250px; height: auto !important"
      />
      <span v-if="false" class="brand-text font-weight-light"
        >Gametia Biobank
        <sup><label class="badge badge-primary">MATCH</label></sup>
      </span>
    </a>
    <ul class="navbar-nav">
      <li class="nav-item" v-for="(route, index) in sidebarRoutes" :key="index">
        <router-link
          :to="route.path"
          :class="{ 'nav-link': true, active: isActiveRoute(route) }"
        >
          <font-awesome-icon class="nav-icon" :icon="route.icon" />
          <span>{{ $t("menu." + route.name) }}</span>
        </router-link>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <language-selection></language-selection>
      <router-link to="/logout" class="nav-link">
        <font-awesome-icon class="nav-icon" icon="power-off" />
      </router-link>
    </ul>
  </nav>
</template>

<script>
//import logo from "@/assets/bola_gametia.png";
import logo from "@/assets/logo_gametia_match.jpg";

import LanguageSelection from "@/components/skeleton/LanguageSelection.vue";
export default {
  name: "Header-View",
  components: {
    LanguageSelection,
  },
  data: () => ({
    logo: logo,
  }),
  computed: {
    sidebarRoutes() {
      // Filter out child routes of DashboardLayout
      let routes = this.$router.options.routes.find(
        (route) => route.path === "/"
      ).children;

      // Filter out routes that are not displayable
      routes = routes.filter((route) => this.isRouteDisplayable(route));

      return routes;
    },
  },
  methods: {
    isRouteDisplayable(route) {
      //Check roles and permissions
      let user = JSON.parse(localStorage.getItem("gametiamatch_user"));
      if (route.meta.roles) {
        const found = user.roles.some((r) => route.meta.roles.indexOf(r) >= 0);
        if (!found) {
          return false;
        }
      }
      // Check if the route is displayable

      if (route.meta.display === undefined) {
        return true;
      }
      return route.meta.display;
    },
    isActiveRoute(route) {
      // Check if the current route matches the provided route
      return this.$route.path === route.path;
    },
  },
};
</script>
<style scoped>
.main-headerr {
  background-color: rgba(244, 180, 196, 1);
}
.brand-image {
  height: 45px !important;
  width: auto;
}
.active {
  background-color: rgba(244, 180, 196, 1) !important;
  color: white !important;
  border-radius: 2rem;
}
.nav-icon {
  padding-right: 5px;
}
</style>
