<template>
  <section>
    <paginated-table-component
      :searchable="true"
      :pageSize="20"
      :data="donantes"
      :isLoading="isLoading"
      class="table"
    >
      <template v-slot:card-header>
        <span> </span>
        <div class="card-tools">
          <span class="btn-tool">
            <train-donor-component @reload="getDonors" />
          </span>
        </div>
      </template>
      <template v-slot:header>
        <th>ID</th>
        <th>Nº</th>
        <th>Género</th>
      </template>
      <template v-slot:body="{ data }">
        <tr v-for="(item, index) in data" :key="index">
          <td>{{ item.id }}</td>
          <td>{{ item.numeroDonante }}</td>
          <td>{{ item.genero }}</td>
        </tr>
      </template>
    </paginated-table-component>
  </section>
</template>
<script>
import TrainDonorComponent from "@/components/TrainDonorComponent.vue";

export default {
  components: { TrainDonorComponent },
  data: () => ({
    donantes: [],
    isLoading: false,
  }),
  mounted: function () {
    this.getDonors();
  },
  methods: {
    async getDonors() {
      this.isLoading = true;
      try {
        let donantesTemp = await this.$api.getDonors();
        //donantesTemp has 2 keys, HOMBRE and MUJER, merge them into one array
        donantesTemp = donantesTemp.HOMBRE.concat(donantesTemp.MUJER);
        this.donantes = donantesTemp;
        console.log(this.donantes);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
};
</script>
